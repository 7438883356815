import React from 'react';
import _ from 'lodash';
// import { Link } from 'react-router-dom';
import cn from 'classnames';

import ynetLogo from 'assets/client/courses_images/ynet-course.png';
import infinityLabsLogo from 'assets/client/courses_images/infinity-labs-1.png';
import ciscoCobLogo from 'assets/client/courses_images/cisco-and-cob.png';
import nessLogo from 'assets/client/courses_images/ness-1.png';
import ciscoCobNayaLogo from 'assets/client/courses_images/cob-cisco-naya.jpg';

import { FIRST_YNET_COURSE_ROUTE, SECOND_YNET_COURSE_ROUTE } from 'client/constants';

import css from './CoursesList.module.scss';

const COURSE_STATUS = {
	ACTIVE: 'ACTIVE',
	OPENS_SOON: 'OPENS_SOON',
	DISABLED: 'DISABLED',
	REGISTRATION_ENDED: 'REGISTRATION_ENDED',
};

const BUTTON_LABELS = {
	ACTIVE: 'הירשמו כאן',
	OPENS_SOON: 'הרשמה תפתח בקרוב',
	DISABLED: 'ההרשמה תפתח בקרוב',
	REGISTRATION_ENDED: 'ההרשמה הסתיימה',
};

export const CoursesList = () => {
	return (
		<div className={css.courses}>
			{_.map(COURSES, (course, i) => {
				return (
					<div
						key={i}
						className={cn(
							css.course,
							course.status === COURSE_STATUS.REGISTRATION_ENDED
								? css.disabled
								: css[course.status.toLowerCase()],
							css[course.className]
						)}
					>
						<div className={css.logoAndTitle}>
							<div className={cn(css.logo)}>
								<img src={course.logo} alt="" />
							</div>
							<h6 className={css.title}>{course.title}</h6>
							<p className={css.subTitle} dangerouslySetInnerHTML={{ __html: course.subTitle }}></p>
						</div>
						{course.status === COURSE_STATUS.ACTIVE ? (
							<a href={course.link} rel="noopener noreferrer" target="_blank" className={css.btn}>
								<span>{BUTTON_LABELS[course.status]}</span>
							</a>
						) : (
							<span className={css.btn}>
								<span>{BUTTON_LABELS[course.status]}</span>
							</span>
						)}
						<span className={css.priceTag}>{course.priceTag}</span>
					</div>
				);
			})}
		</div>
	);
};

const COURSES = [
	{
		logo: ynetLogo,
		title: 'יצירת תוכן',
		subTitle:
			'אמנות ה-Story Telling | כתיבה עיתונאית  <i></i>  ראיונות ותחקיר | סושיאל | מיתוג  <i></i> יצירת תוכן ככלי הסברתי',
		link: FIRST_YNET_COURSE_ROUTE,
		priceTag: 'ללא עלות',
		status: COURSE_STATUS.REGISTRATION_ENDED,
		className: 'ynet',
	},
	{
		logo: ciscoCobLogo,
		title: 'ניהול רשתות',
		subTitle: '(CCNA)',
		link: 'https://connectedbusinesses.co.il/course/net-mgmt-info-sec/?mode=clm&cid=533906',
		priceTag: 'בעלות סמלית',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: ciscoCobLogo,
		title: 'עיצוב ממשק וחוויית משתמש ',
		subTitle: '(UX/UI)',
		link: 'https://connectedbusinesses.co.il/course/ui-ux/?mode=clm&cid=533906',
		priceTag: 'בעלות סמלית',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: ciscoCobNayaLogo,
		title: 'ניתוח נתונים',
		subTitle: '(DATA ANALYST)',
		link: 'https://connectedbusinesses.co.il/course/data-research-analyst/?mode=clm&cid=533906',
		priceTag: 'בעלות סמלית',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: ciscoCobLogo,
		title: 'מכירות והשפעה',
		subTitle: '(TECH SALES)',
		link: 'https://connectedbusinesses.co.il/course/sales/?mode=clm&cid=533906',
		priceTag: 'בעלות סמלית',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: infinityLabsLogo,
		title: 'פיתוח תוכנה',
		subTitle: '',
		link: 'https://ilrd.infinitylabs.co.il/tasmc-software-development/?utm_content=referid=1193',
		priceTag: 'ללא עלות',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: infinityLabsLogo,
		title: 'דבאופס',
		subTitle: '',
		link: 'https://ilrd.infinitylabs.co.il/tasmc-devops-pro/?utm_content=referid=1193',
		priceTag: 'ללא עלות',
		status: COURSE_STATUS.ACTIVE,
	},
	{
		logo: nessLogo,
		title: 'פיתוח אוטומציה ובדיקות ידניות',
		subTitle: '',
		link: 'https://l-p.site/ness_course_testing',
		priceTag: 'ללא עלות',
		status: COURSE_STATUS.ACTIVE,
		className: 'ness',
	},
	{
		logo: nessLogo,
		title: 'תמיכה טכנית',
		subTitle: '',
		link: 'https://l-p.site/ness_course_hd',
		priceTag: 'ללא עלות',
		status: COURSE_STATUS.ACTIVE,
	},
];
